var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c("b-card", { staticClass: "card-border-blue-light" }, [
          _c(
            "div",
            { staticClass: "px-0", class: _vm.fillClassContainer },
            [
              _c(
                "b-form",
                { on: { submit: _vm.submitForm } },
                [
                  _c("div", { staticClass: "card-collapsible px-0" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.handleCollapsibleHeader(
                              "crew-member-information"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "title w-90" },
                          [
                            _c("b-icon", {
                              staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                              attrs: { icon: "info-circle" },
                            }),
                            _c("span", { staticClass: "ml-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.FormMSG(64, "Crew member information")
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "cursor-pointer d-flex justify-content-end w-10",
                          },
                          [
                            !_vm.configAccordion["crew-member-information"]
                              .isVisible
                              ? _c(_vm.getIconLucide("ChevronDown"), {
                                  tag: "component",
                                  attrs: { color: "#06263E", size: 22 },
                                })
                              : _vm._e(),
                            _vm.configAccordion["crew-member-information"]
                              .isVisible
                              ? _c(_vm.getIconLucide("ChevronUp"), {
                                  tag: "component",
                                  attrs: { color: "#06263E", size: 22 },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        visible:
                          _vm.configAccordion["crew-member-information"]
                            .isVisible,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-card-collapsible" },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(10, "Last name"),
                                        "label-for": "name",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        class: {
                                          "is-invalid":
                                            _vm.isSubmitted &&
                                            _vm.$v.newUser.userName.$error,
                                        },
                                        attrs: { id: "name", type: "text" },
                                        model: {
                                          value: _vm.newUser.userName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newUser,
                                              "userName",
                                              $$v
                                            )
                                          },
                                          expression: "newUser.userName",
                                        },
                                      }),
                                      _vm.isSubmitted &&
                                      !_vm.$v.newUser.userName.required
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      65,
                                                      "Please, length should be at least 2"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(11, "First name"),
                                        "label-for": "firstName",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        class: {
                                          "is-invalid":
                                            _vm.isSubmitted &&
                                            _vm.$v.newUser.userFirstName.$error,
                                        },
                                        attrs: {
                                          id: "firstName",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.newUser.userFirstName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newUser,
                                              "userFirstName",
                                              $$v
                                            )
                                          },
                                          expression: "newUser.userFirstName",
                                        },
                                      }),
                                      _vm.isSubmitted &&
                                      !_vm.$v.newUser.userFirstName.required
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      65,
                                                      "Please, length should be at least 2"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(12, "Email"),
                                        "label-for": "email",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        class: {
                                          "is-invalid":
                                            _vm.isSubmitted &&
                                            _vm.$v.newUser.email.$error,
                                        },
                                        attrs: {
                                          id: "email",
                                          type: "text",
                                          disabled: _vm.editData !== null,
                                        },
                                        model: {
                                          value: _vm.newUser.email,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.newUser, "email", $$v)
                                          },
                                          expression: "newUser.email",
                                        },
                                      }),
                                      _vm.isSubmitted &&
                                      !_vm.$v.newUser.email.emailValid
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      66,
                                                      "Please, enter valid email"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(13, "Language"),
                                        "label-for": "language",
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          options: _vm.optionsForLanguages,
                                        },
                                        model: {
                                          value: _vm.newUser.language,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newUser,
                                              "language",
                                              $$v
                                            )
                                          },
                                          expression: "newUser.language",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "card-collapsible px-0 mt-4 mb-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.handleCollapsibleHeader(
                                "work-general-information"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "title w-90" },
                            [
                              _c("b-icon", {
                                staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                                attrs: { icon: "file-earmark-text" },
                              }),
                              _c("span", { staticClass: "ml-3" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(49, "Work general information")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "cursor-pointer d-flex justify-content-end w-10",
                            },
                            [
                              !_vm.configAccordion["work-general-information"]
                                .isVisible
                                ? _c(_vm.getIconLucide("ChevronDown"), {
                                    tag: "component",
                                    attrs: { color: "#06263E", size: 22 },
                                  })
                                : _vm._e(),
                              _vm.configAccordion["work-general-information"]
                                .isVisible
                                ? _c(_vm.getIconLucide("ChevronUp"), {
                                    tag: "component",
                                    attrs: { color: "#06263E", size: 22 },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        visible:
                          _vm.configAccordion["work-general-information"]
                            .isVisible,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-card-collapsible pt-0" },
                        [
                          _c("department-selection", {
                            ref: "depfun",
                            attrs: {
                              "edit-data": _vm.newUser,
                              "is-submitted": _vm.isSubmitted,
                              "burger-field": "",
                            },
                            on: {
                              "department-function-selector:departmentSelected":
                                _vm.handleDepartmentChange,
                              "department-function-selector:functionSelected":
                                _vm.handleFunctionChange,
                            },
                          }),
                          !_vm.isFilmSingle && !_vm.isFilmSingleFree
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "startDate",
                                              expression: "'startDate'",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.FormMSG(
                                              50,
                                              "Start date"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "uni-id",
                                                rawName: "v-uni-id",
                                                value: "startDate",
                                                expression: "'startDate'",
                                              },
                                            ],
                                            attrs: {
                                              value:
                                                _vm.newUser.startDate &&
                                                _vm.newUser.startDate.split(
                                                  "T"
                                                )[0],
                                              type: "date",
                                            },
                                            on: { input: _vm.updateStartDate },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "endDate",
                                              expression: "'endDate'",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.FormMSG(
                                              51,
                                              "End date (optional)"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "uni-id",
                                                rawName: "v-uni-id",
                                                value: "endDate",
                                                expression: "'endDate'",
                                              },
                                            ],
                                            attrs: {
                                              value:
                                                _vm.newUser.endDate &&
                                                _vm.newUser.endDate.split(
                                                  "T"
                                                )[0],
                                              type: "date",
                                            },
                                            on: { input: _vm.updateEndDate },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "daysforeseens",
                                              expression: "'daysforeseens'",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.FormMSG(
                                              52,
                                              "Number of workign days foreseen"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "uni-id",
                                                rawName: "v-uni-id",
                                                value: "daysforeseens",
                                                expression: "'daysforeseens'",
                                              },
                                            ],
                                            attrs: { type: "number" },
                                            model: {
                                              value: _vm.newUser.daysForeseen,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newUser,
                                                  "daysForeseen",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "newUser.daysForeseen",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "employeeType",
                                              expression: "'employeeType'",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.FormMSG(
                                              53,
                                              "Employee type"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options:
                                                _vm.employeeStatusOptions,
                                            },
                                            model: {
                                              value: _vm.newUser.employeeStatus,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newUser,
                                                  "employeeStatus",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "newUser.employeeStatus",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isFilmSingle && !_vm.isFilmSingleFree
                            ? _c(
                                "b-row",
                                [
                                  [0, 1, 3, 4].includes(_vm.analyticPeriodType)
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "costCenterPrepsType",
                                                  expression:
                                                    "'costCenterPrepsType'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.labelCostCenterPreps,
                                              },
                                            },
                                            [
                                              _c("treeselect", {
                                                class: {
                                                  "is-invalid":
                                                    _vm.isSubmitted &&
                                                    _vm.$v.newUser
                                                      .costCenterPrepsType
                                                      .$error,
                                                },
                                                attrs: {
                                                  multiple: false,
                                                  clearable: false,
                                                  options:
                                                    _vm.costCenterPrepsOptions,
                                                  "disable-branch-nodes": true,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "option-label",
                                                      fn: function ({ node }) {
                                                        return _c("div", {}, [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "treeselect-label",
                                                              attrs: {
                                                                title:
                                                                  node.label,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  node.label
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2657349975
                                                ),
                                                model: {
                                                  value:
                                                    _vm.newUser
                                                      .costCenterPrepsType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newUser,
                                                      "costCenterPrepsType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newUser.costCenterPrepsType",
                                                },
                                              }),
                                              _vm.isSubmitted &&
                                              _vm.$v.newUser.costCenterPrepsType
                                                .$error
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              70,
                                                              _vm.msgPrep
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  [1, 2, 4].includes(_vm.analyticPeriodType)
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value:
                                                    "costCenterShootingType",
                                                  expression:
                                                    "'costCenterShootingType'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  55,
                                                  "Cost center shooting"
                                                ),
                                              },
                                            },
                                            [
                                              _c("treeselect", {
                                                class: {
                                                  "is-invalid":
                                                    _vm.isSubmitted &&
                                                    _vm.$v.newUser
                                                      .costCenterShootingType
                                                      .$error,
                                                },
                                                attrs: {
                                                  multiple: false,
                                                  clearable: false,
                                                  options:
                                                    _vm.costCenterShootingOptions,
                                                  "disable-branch-nodes": true,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "option-label",
                                                      fn: function ({ node }) {
                                                        return _c("div", {}, [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "treeselect-label",
                                                              attrs: {
                                                                title:
                                                                  node.label,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  node.label
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2657349975
                                                ),
                                                model: {
                                                  value:
                                                    _vm.newUser
                                                      .costCenterShootingType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newUser,
                                                      "costCenterShootingType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newUser.costCenterShootingType",
                                                },
                                              }),
                                              _vm.isSubmitted &&
                                              _vm.$v.newUser
                                                .costCenterShootingType.$error
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              71,
                                                              _vm.msgShooting
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  [2, 3, 4].includes(_vm.analyticPeriodType)
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "12",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              directives: [
                                                {
                                                  name: "uni-for",
                                                  rawName: "v-uni-for",
                                                  value: "costCenterWrapType",
                                                  expression:
                                                    "'costCenterWrapType'",
                                                },
                                              ],
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  56,
                                                  "Cost center wrap"
                                                ),
                                              },
                                            },
                                            [
                                              _c("treeselect", {
                                                class: {
                                                  "is-invalid":
                                                    _vm.isSubmitted &&
                                                    _vm.$v.newUser
                                                      .costCenterWrapType
                                                      .$error,
                                                },
                                                attrs: {
                                                  multiple: false,
                                                  clearable: false,
                                                  options:
                                                    _vm.costCenterWrapOptions,
                                                  "disable-branch-nodes": true,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "option-label",
                                                      fn: function ({ node }) {
                                                        return _c("div", {}, [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "treeselect-label",
                                                              attrs: {
                                                                title:
                                                                  node.label,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  node.label
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2657349975
                                                ),
                                                model: {
                                                  value:
                                                    _vm.newUser
                                                      .costCenterWrapType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newUser,
                                                      "costCenterWrapType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newUser.costCenterWrapType",
                                                },
                                              }),
                                              _vm.isSubmitted &&
                                              _vm.$v.newUser.costCenterWrapType
                                                .$error
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              72,
                                                              _vm.msgWrap
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          directives: [
                                            {
                                              name: "uni-for",
                                              rawName: "v-uni-for",
                                              value: "bcfRate",
                                              expression: "'bcfRate'",
                                            },
                                          ],
                                          attrs: {
                                            label: _vm.FormMSG(
                                              57,
                                              "Budget cost factor"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "uni-id",
                                                rawName: "v-uni-id",
                                                value: "bcfRate",
                                                expression: "'bcfRate'",
                                              },
                                            ],
                                            attrs: {
                                              type: "number",
                                              step: "0.00000000001",
                                              min: "1",
                                              placeholder: "1.0",
                                            },
                                            model: {
                                              value:
                                                _vm.newUser.companyCostFactor,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newUser,
                                                  "companyCostFactor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "newUser.companyCostFactor",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "info-message" },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("info", {
                                                    attrs: { size: 16 },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "label" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        58,
                                                        "budget cost = salary*factor"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { sm: "12" } }, [
                                _c(
                                  "fieldset",
                                  { staticClass: "card-inside" },
                                  [
                                    _c(
                                      "legend",
                                      { staticClass: "card-inside" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(59, "Document package")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-row",
                                      {
                                        staticClass:
                                          "inline-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            attrs: {
                                              sm: "12",
                                              md: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                directives: [
                                                  {
                                                    name: "uni-for",
                                                    rawName: "v-uni-for",
                                                    value: "documentPackageId",
                                                    expression:
                                                      "'documentPackageId'",
                                                  },
                                                ],
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    60,
                                                    "Assign document package"
                                                  ),
                                                },
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    options:
                                                      _vm.documentPackageOptions,
                                                    id: "documentPackageId",
                                                    label: "name",
                                                    reduce: (option) =>
                                                      option.id,
                                                    clearable: false,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newUser
                                                        .documentPackageId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newUser,
                                                        "documentPackageId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newUser.documentPackageId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.newUser.documentPackageId > 0
                                          ? _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "12",
                                                  lg: "6",
                                                  xl: "6",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { attrs: { sm: "12" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "btn-rounded",
                                                            on: {
                                                              click:
                                                                _vm.handleViewDocumentPackage,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "icon",
                                                              },
                                                              [
                                                                _c("eye", {
                                                                  attrs: {
                                                                    color:
                                                                      "#5B6164D6",
                                                                    size: 16,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        61,
                                                                        "View document package"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                !_vm.isFilmSingle &&
                                                !_vm.isFilmSingleFree
                                                  ? _c(
                                                      "b-row",
                                                      { staticClass: "pt-3" },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: { sm: "12" },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "pj-cb pb-1",
                                                                attrs: {
                                                                  size: "lg",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.newUser
                                                                      .isAutomaticSentContract,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.newUser,
                                                                        "isAutomaticSentContract",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "newUser.isAutomaticSentContract",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      62,
                                                                      "Send automatically once invitation is accepted"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.isFilmSingle ||
                                                _vm.isFilmSingleFree
                                                  ? _c(
                                                      "b-row",
                                                      { staticClass: "pt-3" },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: { sm: "12" },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "btn-rounded",
                                                                on: {
                                                                  click:
                                                                    _vm.handleResendDocumentPackage,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "icon",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "corner-up-left",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "#5B6164D6",
                                                                          size: 16,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "label",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            63,
                                                                            "Resend document package"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("memo-deal", {
                    ref: "memo",
                    attrs: {
                      "edit-data": _vm.contractTemplate,
                      displayCopyPaste: true,
                      "start-date-update": _vm.newUser.startDate,
                      "end-date-update": _vm.newUser.endDate,
                      "days-foreseen-update": _vm.newUser.daysForeseen,
                      "employee-status-update": _vm.newUser.employeeStatus,
                      "cost-center-preps-type-update":
                        _vm.newUser.costCenterPrepsType,
                      "cost-center-shooting-type-update":
                        _vm.newUser.costCenterShootingType,
                      "cost-center-wrap-type-update":
                        _vm.newUser.costCenterWrapType,
                      "company-cost-factor-update":
                        _vm.newUser.companyCostFactor,
                      "document-package-id-update":
                        _vm.newUser.documentPackageId,
                      "is-automatic-sent-contract-update":
                        _vm.newUser.isAutomaticSentContract,
                      "is-submitted": _vm.isSubmitted,
                      "fill-class-container": "",
                      "disable-save-deal-memo-template":
                        _vm.disableSaveTemplate || _vm.loadingAddProject,
                      "show-pay-code": _vm.showPayCode,
                    },
                    on: {
                      change: _vm.handleMemoDealChange,
                      "memo-deal:fields:invalid": _vm.isInvalidateFields,
                      "memo-deal:fields:clear-error": _vm.clearFieldsError,
                      "memo-deal:save-template": _vm.saveTemplate,
                      "memo-deal:department-function:required":
                        _vm.handleCcn2642,
                    },
                  }),
                  _c("div", { staticClass: "card-collapsible px-0 mb-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.handleCollapsibleHeader("authorization")
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "title w-90" },
                          [
                            _c("b-icon", {
                              staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                              attrs: { icon: "key" },
                            }),
                            _c("span", { staticClass: "ml-3" }, [
                              _vm._v(_vm._s(_vm.FormMSG(16, "Authorization"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "cursor-pointer d-flex justify-content-end w-10",
                          },
                          [
                            !_vm.configAccordion["authorization"].isVisible
                              ? _c(_vm.getIconLucide("ChevronDown"), {
                                  tag: "component",
                                  attrs: { color: "#06263E", size: 22 },
                                })
                              : _vm._e(),
                            _vm.configAccordion["authorization"].isVisible
                              ? _c(_vm.getIconLucide("ChevronUp"), {
                                  tag: "component",
                                  attrs: { color: "#06263E", size: 22 },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        visible: _vm.configAccordion["authorization"].isVisible,
                      },
                    },
                    [
                      _c("div", { staticClass: "content-card-collapsible" }, [
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "12", md: "12" } },
                                    [
                                      _c(
                                        "fieldset",
                                        { staticClass: "card-inside" },
                                        [
                                          _c(
                                            "legend",
                                            { staticClass: "card-inside" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      301,
                                                      "Application rights"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "30px",
                                                "margin-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "mb-3",
                                                  attrs: { md: "4" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "pj-cb pb-1",
                                                      attrs: {
                                                        size: "lg",
                                                        value: true,
                                                        "unchecked-value": false,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.newUser
                                                            .canManageCrewList,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newUser,
                                                            "canManageCrewList",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newUser.canManageCrewList",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              2970,
                                                              "Manage crew list"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "30px",
                                              },
                                            },
                                            [
                                              _vm.useDoc
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: {
                                                            size: "lg",
                                                            value: true,
                                                            "unchecked-value": false,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canManageDocuments,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canManageDocuments",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canManageDocuments",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  2971,
                                                                  "Manage documents"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "mb-3",
                                                  attrs: { md: "4" },
                                                },
                                                [
                                                  _vm.useDoc
                                                    ? _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: {
                                                            size: "lg",
                                                            value: true,
                                                            "unchecked-value": false,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canManageDocumentPackage,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canManageDocumentPackage",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canManageDocumentPackage",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  2440,
                                                                  "Manage document package"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm.useCallSheet
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: { size: "lg" },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canManageCallSheets,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canManageCallSheets",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canManageCallSheets",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  2450,
                                                                  "Manage call sheets"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "30px",
                                              },
                                            },
                                            [
                                              _vm.useAcc
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: {
                                                            size: "lg",
                                                            value: true,
                                                            "unchecked-value": false,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canManageAccommodations,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canManageAccommodations",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canManageAccommodations",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  2973,
                                                                  "Manage accomodations"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.useLoc
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: {
                                                            size: "lg",
                                                            value: true,
                                                            "unchecked-value": false,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canManageLocations,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canManageLocations",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canManageLocations",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  2449,
                                                                  "Manage locations"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.useTrans
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: {
                                                            size: "lg",
                                                            value: true,
                                                            "unchecked-value": false,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canManageTransport,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canManageTransport",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canManageTransport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  24409,
                                                                  "Manage transport"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "30px",
                                              },
                                            },
                                            [
                                              _vm.useImportExport
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: { size: "lg" },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canExportImport,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canExportImport",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canExportImport",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  2455,
                                                                  "Can import/export"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.useBudget
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: { size: "lg" },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canViewDepartmentBudget,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canViewDepartmentBudget",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canViewDepartmentBudget",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  2690,
                                                                  "View department budget"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.useBudget
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: {
                                                            size: "lg",
                                                            value: true,
                                                            "unchecked-value": false,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canViewGlobalBudget,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canViewGlobalBudget",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canViewGlobalBudget",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  24430,
                                                                  "View global budget"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "30px",
                                              },
                                            },
                                            [
                                              _vm.useGreenTable
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: { size: "lg" },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canViewGreenTable,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canViewGreenTable",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canViewGreenTable",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  24568,
                                                                  "View Green table"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.useReporting
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: { size: "lg" },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canAccessGreenReporting,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canAccessGreenReporting",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canAccessGreenReporting",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  24560,
                                                                  "View Green reporting screens"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.useCarbonRemoval
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: {
                                                            size: "lg",
                                                            value: true,
                                                            "unchecked-value": false,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canManageCarbonRemoval,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canManageCarbonRemoval",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canManageCarbonRemoval",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  24480,
                                                                  "Manage carbon removal"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "30px",
                                              },
                                            },
                                            [
                                              _vm.useWaste
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: { size: "lg" },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canViewWasteScreens,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canViewWasteScreens",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canViewWasteScreens",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  24455,
                                                                  "View Waste screens"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.useTipOfDay
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { md: "4" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "pj-cb pb-1",
                                                          attrs: { size: "lg" },
                                                          model: {
                                                            value:
                                                              _vm.newUser
                                                                .canManageTipOfTheDay,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newUser,
                                                                "canManageTipOfTheDay",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newUser.canManageTipOfTheDay",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  24405,
                                                                  "Manage tip of the day"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "30px",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "mb-3",
                                                  attrs: { md: "4" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass:
                                                        "pj-cb pb-1 mb-3",
                                                      attrs: { size: "lg" },
                                                      model: {
                                                        value:
                                                          _vm.newUser
                                                            .canAccessConfig,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newUser,
                                                            "canAccessConfig",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newUser.canAccessConfig",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              246,
                                                              "Can access config"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.needValidation
                                        ? _c(
                                            "fieldset",
                                            { staticClass: "card-inside" },
                                            [
                                              _c(
                                                "legend",
                                                { staticClass: "card-inside" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          302,
                                                          "Validation rights"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "lh-22" },
                                                [
                                                  _c("h6", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            303,
                                                            "Validation level for PO, Expenses and Timehseets"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("b-form-select", {
                                                    staticClass: "pj-cb pb-1",
                                                    attrs: {
                                                      options:
                                                        _vm.optionsForValidationLevel,
                                                      id: "valLevel",
                                                      size: "md",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser
                                                          .validationLevel,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "validationLevel",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.validationLevel",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.useDoc
                                        ? _c(
                                            "fieldset",
                                            { staticClass: "card-inside" },
                                            [
                                              _c(
                                                "legend",
                                                { staticClass: "card-inside" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          305,
                                                          "Onboarding validation role"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "lh-22" },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb mb-3 pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newUser
                                                                    .gateKeeper,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newUser,
                                                                      "gateKeeper",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newUser.gateKeeper",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      266,
                                                                      "Gate Keeper"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb mb-3 pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newUser
                                                                    .productionAccountant,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newUser,
                                                                      "productionAccountant",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newUser.productionAccountant",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      261,
                                                                      "Production Account"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb mb-3 pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newUser
                                                                    .upm,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newUser,
                                                                      "upm",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newUser.upm",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      262,
                                                                      "UPM"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb mb-3 pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newUser
                                                                    .lineProducer,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newUser,
                                                                      "lineProducer",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newUser.lineProducer",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      263,
                                                                      "Line Producer"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb mb-3 pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newUser
                                                                    .studio,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newUser,
                                                                      "studio",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newUser.studio",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      265,
                                                                      "Studio"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb mb-3 pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newUser
                                                                    .canValidateI9,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newUser,
                                                                      "canValidateI9",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newUser.canValidateI9",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      270,
                                                                      "Verify I-9"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.usePoAndExp
                                        ? _c(
                                            "fieldset",
                                            { staticClass: "card-inside" },
                                            [
                                              _c(
                                                "legend",
                                                { staticClass: "card-inside" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          365,
                                                          "Editing authorization"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "lh-22" },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _vm.useExp
                                                        ? _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "pj-cb mb-3 pb-1",
                                                                  attrs: {
                                                                    size: "lg",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .newUser
                                                                        .canEditExpense,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.newUser,
                                                                          "canEditExpense",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "newUser.canEditExpense",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          250,
                                                                          "Can edit Expenses"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.usePo
                                                        ? _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "pj-cb mb-3 pb-1",
                                                                  attrs: {
                                                                    size: "lg",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .newUser
                                                                        .canEditPo,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.newUser,
                                                                          "canEditPo",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "newUser.canEditPo",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          255,
                                                                          "Can edit PO"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.useFinance
                                        ? _c(
                                            "fieldset",
                                            { staticClass: "card-inside" },
                                            [
                                              _c(
                                                "legend",
                                                { staticClass: "card-inside" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          360,
                                                          "Accounting/Budget situation"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "lh-22" },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb mb-3 pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newUser
                                                                    .canLockBudget,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newUser,
                                                                      "canLockBudget",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newUser.canLockBudget",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      361,
                                                                      "Can lock/unlock initial budget"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb mb-3 pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newUser
                                                                    .canModifyEstimatedFinalCost,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newUser,
                                                                      "canModifyEstimatedFinalCost",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newUser.canModifyEstimatedFinalCost",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      362,
                                                                      "Can modify EFC"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb mb-3 pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newUser
                                                                    .canEditAndAddCode,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newUser,
                                                                      "canEditAndAddCode",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newUser.canEditAndAddCode",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      363,
                                                                      "Can edit/add codes"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.showEntryForCrew
                                        ? _c(
                                            "fieldset",
                                            { staticClass: "card-inside" },
                                            [
                                              _c(
                                                "legend",
                                                { staticClass: "card-inside" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          356,
                                                          "Entry for someone else"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "lh-22" },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _vm.showEntryForCrewTimesheet
                                                        ? _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "pj-cb mb-3 pb-1",
                                                                  attrs: {
                                                                    size: "lg",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.handleUserEntryLevelChange,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userEntryLevelOnForm
                                                                        .timeSheets,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.userEntryLevelOnForm,
                                                                          "timeSheets",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "userEntryLevelOnForm.timeSheets",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          251,
                                                                          "Timesheets"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.showEntryForCrewExpenses
                                                        ? _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "pj-cb mb-3 pb-1",
                                                                  attrs: {
                                                                    size: "lg",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.handleUserEntryLevelChange,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userEntryLevelOnForm
                                                                        .expenses,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.userEntryLevelOnForm,
                                                                          "expenses",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "userEntryLevelOnForm.expenses",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          252,
                                                                          "Expenses"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.showEntryForCrewPo
                                                        ? _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "pj-cb mb-3 pb-1",
                                                                  attrs: {
                                                                    size: "lg",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.handleUserEntryLevelChange,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userEntryLevelOnForm
                                                                        .po,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.userEntryLevelOnForm,
                                                                          "po",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "userEntryLevelOnForm.po",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          253,
                                                                          "PO"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "h6",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm
                                                              .userEntryLevelOnForm
                                                              .po ||
                                                            _vm
                                                              .userEntryLevelOnForm
                                                              .expenses ||
                                                            _vm
                                                              .userEntryLevelOnForm
                                                              .timeSheets,
                                                          expression:
                                                            "userEntryLevelOnForm.po || userEntryLevelOnForm.expenses || userEntryLevelOnForm.timeSheets",
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                357,
                                                                "Department selection"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c("v-select", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm
                                                            .userEntryLevelOnForm
                                                            .po ||
                                                          _vm
                                                            .userEntryLevelOnForm
                                                            .expenses ||
                                                          _vm
                                                            .userEntryLevelOnForm
                                                            .timeSheets,
                                                        expression:
                                                          "userEntryLevelOnForm.po || userEntryLevelOnForm.expenses || userEntryLevelOnForm.timeSheets",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "style-chooser",
                                                    attrs: {
                                                      multiple: "",
                                                      placeholder:
                                                        "Select a department",
                                                      label: "text",
                                                      options:
                                                        _vm.allDepartements,
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.checkDepartment,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "option",
                                                          fn: function (
                                                            option
                                                          ) {
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "pj-cb mb-3 pb-1",
                                                                  attrs: {
                                                                    disabled:
                                                                      "",
                                                                    size: "lg",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      option.selected,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          option,
                                                                          "selected",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "option.selected",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        option.text
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3333001477
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.selectedDepartment,
                                                      callback: function ($$v) {
                                                        _vm.selectedDepartment =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedDepartment",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "fieldset",
                                        { staticClass: "card-inside" },
                                        [
                                          _c(
                                            "legend",
                                            { staticClass: "card-inside" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(1200, "Planning")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "lh-22" },
                                            [
                                              _c("h6", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1201,
                                                        "Validation Fimalac"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _c("b-form-select", {
                                                attrs: {
                                                  options:
                                                    _vm.optionsForValidationLevelFimamac,
                                                  id: "valLevel-fimalac-onboarding",
                                                  size: "md",
                                                },
                                                model: {
                                                  value:
                                                    _vm.newUser
                                                      .planningValidation,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newUser,
                                                      "planningValidation",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newUser.planningValidation",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.isDeterProject === true
                    ? _c(
                        "div",
                        { staticClass: "card-collapsible px-0 mt-3" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                              on: {
                                click: function ($event) {
                                  return _vm.handleCollapsibleHeader("deter")
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "title w-90" },
                                [
                                  _c(_vm.getLucideIcon("UserCog"), {
                                    tag: "component",
                                    attrs: { size: 20 },
                                  }),
                                  _c("span", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(589, "Team session"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end w-10",
                                },
                                [
                                  !_vm.configAccordion["deter"].isVisible
                                    ? _c(_vm.getIconLucide("ChevronDown"), {
                                        tag: "component",
                                        attrs: { color: "#06263E", size: 22 },
                                      })
                                    : _vm._e(),
                                  _vm.configAccordion["deter"].isVisible
                                    ? _c(_vm.getIconLucide("ChevronUp"), {
                                        tag: "component",
                                        attrs: { color: "#06263E", size: 22 },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                visible: _vm.configAccordion["deter"].isVisible,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-card-collapsible" },
                                [
                                  _c(
                                    "b-table-simple",
                                    { attrs: { id: "deterSessionTeamTable" } },
                                    [
                                      _c(
                                        "b-thead",
                                        { attrs: { "head-variant": "dark" } },
                                        [
                                          _c(
                                            "b-tr",
                                            [
                                              _c("b-th"),
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      457,
                                                      "Session 1"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      458,
                                                      "Session 2"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      459,
                                                      "Session 3"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      460,
                                                      "Session 4"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      461,
                                                      "Session 5"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      462,
                                                      "Session 6"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      463,
                                                      "Session 7"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      464,
                                                      "Session 8"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-tbody",
                                        [
                                          _c(
                                            "b-tr",
                                            [
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(465, "Team A")
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session1A,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session1A",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session1A",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session2A,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session2A",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session2A",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session3A,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session3A",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session3A",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session4A,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session4A",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session4A",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session5A,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session5A",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session5A",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session6A,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session6A",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session6A",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session7A,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session7A",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session7A",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session8A,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session8A",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session8A",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-tr",
                                            [
                                              _c("b-th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(466, "Team B")
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session1B,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session1B",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session1B",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session2B,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session2B",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session2B",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session3B,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session3B",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session3B",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session4B,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session4B",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session4B",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session5B,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session5B",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session5B",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session6B,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session6B",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session6B",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session7B,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session7B",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session7B",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-td",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newUser.session8B,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newUser,
                                                          "session8B",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newUser.session8B",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    {
                      staticClass: "d-flex justify-content-center mt-2",
                      style: `display: ${
                        _vm.displayAddBtn ? "block" : "none"
                      } !important;`,
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-button",
                            {
                              ref: "submit",
                              attrs: {
                                type: "submit",
                                size: "lg",
                                variant: "primary",
                                block: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                },
                                [
                                  _vm.editData === null
                                    ? _c("div", { staticClass: "pl-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              30,
                                              "Invite crew member"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.editData !== null
                                    ? _c("div", { staticClass: "pl-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              355,
                                              "Update invitation"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled:
                                  this.disableSaveTemplate ||
                                  _vm.loadingAddProject,
                                size: "lg",
                                variant: "primary",
                                block: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveMemoDealAsTemplate($event)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(this.saveMemoDealAsTemplateTitle()) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }